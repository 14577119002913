import React from "react";


export default function CourseIndexOutcomeGrid(props) {

    let outcomeCells = [];
    for (let i = 0; i < props.courseOutcomes.length; i++) {
        let cell = props.courseOutcomes[i];
        outcomeCells.push(<div key={i} className="flex flex-col">
            <h4 key={i} className="font-bold text-xl pb-2">{cell.outcome}</h4>
            <p key={i} className="font-light text-justify">{cell.description}</p>
        </div>)
    }

    return (
        <div className="grid grid-cols-2 gap-10">
            {outcomeCells}
        </div>
    )
}