import React from "react";
import Layout from "../components/Layout";
import NavigationBar from "../components/NavigationBar";
import ProfilePicture from "../components/ProfilePicture";
import CourseIndexModuleTable from "../components/CourseIndexModuleTable";
import CourseIndexOutcomeGrid from "../components/CourseIndexOutcomeGrid";
import ProgressCircle from "../components/ProgressCircle";
import Seo from "../components/Seo";
import "../styles/signal-bars.css";


const CourseIndex = ({ pageContext }) => {
    const { courseTitle, courseCategory, courseDescription, courseType, courseLevel, courseModules, courseOutcomes, courseSkills } = pageContext;

    let signalBars = "signal-icon ";
    let difficultyRating = "";
    switch (courseLevel) {
        case 1:
            signalBars += "weak";
            difficultyRating = "Beginner";
            break;
        case 2:
            signalBars += "medium";
            difficultyRating = "Intermediate";
            break;
        default:
            signalBars += "strong";
            difficultyRating = "Advanced";
            break;

    }

    function courseMaterials(courseModules) {
        let moduleCount = {
            "video": 0,
            "article": 0,
            "code": 0
        }
        courseModules.map(function(module) {
            moduleCount[module] += 1;
        })
        let outputStr = "";
        let totalElements = 0;
        for (const [key, value] of Object.entries(moduleCount)) {
            if (value > 0) {
                switch(totalElements){
                    case 0:
                        outputStr += key;
                        break;
                    case 1:
                        outputStr += `, ${key}`;
                        break;
                    case 2:
                        outputStr += ` & ${key}`;
                        break;
                }
                totalElements += 1;
            }
        }
        return outputStr;
    }

    const courseMaterialDesc = courseMaterials(courseModules);

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    function skillProgressCircles(skills) {
        let renderedSkills = [];
        skills.map(function(skill) {
            let progressVal = Math.round((skill.level / 3) * 100)
            let skillNameProcessed = toTitleCase(skill.skill.replace('-', ' '));
            const skillLevelStrings = ["Entry", "Intermediate", "Advanced"]

            renderedSkills.push(<div className="flex flex-col items-center">
                <ProgressCircle
                    progress={progressVal}
                    size={100}
                    strokeWidth={15}
                    circleOneStroke='#d9edfe'
                    circleTwoStroke='#C6B8FF'
                    skillName={skill.skill}
                />
                <p className="font-semibold">{skillNameProcessed}</p>
                <p className="font-light"> {skillLevelStrings[skill.level - 1]} level</p>
            </div>)
        })
        return renderedSkills;
    }

    const skillsProgress =  skillProgressCircles(courseSkills);

    return (
        <>
        <div className="bg-cream curved-bg">
            <Layout>
                <Seo title={`${courseTitle} - FlatPack FinTech`} />
                <NavigationBar />
                <div className="flex px-4 py-8">
                    <div className="flex flex-col w-full desktop:w-3/5 justify-center items-center">
                        <h1 className="white-text-shadow text-[4.2rem] text-midnight font-semibold text-right">{courseTitle}</h1>
                        <div className="flex w-full justify-end items-center space-x-8 mt-10">
                            <ProfilePicture />
                            <div className="flex flex-col">
                                <h4 className="text-xl font-light text-midnight">with <strong
                                    className="font-semibold text-midnight">Simon Dunkelman</strong></h4>
                                <div className="flex space-x-2">
                                    <p className="inline-block text-midnight">Course Level: {difficultyRating}</p>
                                    <div className={signalBars}>
                                        <div className="signal-bar rounded-full" />
                                        <div className="signal-bar rounded-full" />
                                        <div className="signal-bar rounded-full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-8 font-light text-lg break-normal">
                            {courseDescription}
                        </div>
                    </div>
                    <div className="w-2/5 min-h-full relative">
                        <div className="hidden desktop:block fixed left-1/2 translate-x-1/4 top-1/2 -translate-y-1/4">
                            <div className="flex flex-col items-center mx-8 py-4 px-4 bg-silver/75 rounded-lg max-w-md space-y-4">
                                <button
                                    className="rounded-lg bg-blue px-2 py-2 text-white font-semibold text-2xl">Subscribe
                                    Now!
                                </button>
                                <p class="text-midnight font-light text-sm text-center whitespace-pre-line">Sign up now and get full access to code examples,
                                    more courses, as well as read articles before they are released. </p>
                                <p className="text-midnight text-lg font-semibold">Subscription Includes:</p>
                                <ul className="coin-list text-left text-midnight">
                                    <li>All courses to build in SaaS, Crypto & NFT</li>
                                    <li>Code examples & resources to speed up building</li>
                                    <li>Exclusive Pro members chat</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    <div className="mt-16">
        <Layout>
            <div className="flex flex-col desktop:w-3/5 px-2 justify-center">
                <h3 className="text-3xl font-semibold pb-8 mobile:text-center">What's inside</h3>
                <p className="text-lg pb-6">This course contains {courseMaterialDesc}</p>
                <CourseIndexModuleTable courseModules={courseModules} />
            </div>
        </Layout>
    </div>
    <div className="mt-16 bg-lightsteelblue inverted-curved-bg pt-8 pb-12">
        <Layout>
            <div className="flex flex-col desktop:w-3/5 px-2 justify-center">
                <h3 className="text-3xl font-semibold pb-8 mobile:text-center">You'll build</h3>
                <CourseIndexOutcomeGrid courseOutcomes={courseOutcomes} />
            </div>
        </Layout>
    </div>
    <div className="bg-honeydew inverted-curved-bg py-8 -mt-4">
        <Layout>
            <div className="flex flex-col desktop:w-3/5 px-2 justify-center">
                <h3 className="text-3xl font-semibold pb-8 mobile:text-center">Prerequisites</h3>
                <p className="font-light text-lg pb-8">Before starting this course we recommend you have the following
                    skills in your toolbox:</p>
                <div className="flex space-x-4 justify-center">
                    <div className="grid grid-cols-3 gap-6">
                        {skillsProgress}
                    </div>
                </div>
            </div>
        </Layout>
    </div>
    </>
    )
}

export default CourseIndex;