import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import '../styles/progress-circles.css';


const ProgressCircle = (props) => {
    const [offset, setOffset] = useState(0);
    const circleRef = useRef(null);
    const {
        size,
        progress,
        strokeWidth,
        circleOneStroke,
        circleTwoStroke,
        skillName,
    } = props;

    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    // Insert Font Awesome icons into SVG using unicode and web fonts
    const skillIcons = {
    "python": <text
        x={`${center}`}
        // y={`${center}`}
        y="60%"
        className="svg-circle-text-brand text-4xl">
        &#xf3e2;
    </text>,
    "javascript": <text
        x={`${center}`}
        // y={`${center}`}
        y="60%"
        className="svg-circle-text-brand text-4xl">
        &#xf3b9;
    </text>,
    "web-development": <text
        x={`${center}`}
        // y={`${center}`}
        y="60%"
        className="svg-circle-text-brand text-4xl font-bold">
        &#xf13b;
    </text>,
    "solidity": <text
        x={`${center}`}
        // y={`${center}`}
        y="60%"
        className="svg-circle-text-brand text-4xl">
        &#xf42e;
    </text>,
    "design": <text
        x={`${center}`}
        // y={`${center}`}
        y="60%"
        className="svg-circle-text-solid text-4xl font-bold">
        &#xf5ae;
    </text>
    };

    const icon = skillIcons[skillName];

    useEffect(() => {
        const progressOffset = ((100 - progress) / 100) * circumference;
        setOffset(progressOffset);

        circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';

    }, [setOffset, progress, circumference, offset]);

    return (
        <>
            <svg
                className="svg"
                width={size}
                height={size}
            >
                <circle
                    className="svg-circle-bg"
                    stroke={circleOneStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                />
                <circle
                    className="svg-circle"
                    ref={circleRef}
                    stroke={circleTwoStroke}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
                {icon}
            </svg>
        </>
    );
}

ProgressCircle.propTypes = {
    size: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number.isRequired,
    circleOneStroke: PropTypes.string.isRequired,
    circleTwoStroke: PropTypes.string.isRequired,
    skillName: PropTypes.string.isRequired
}

export default ProgressCircle;