import React from "react";
import {Link} from "gatsby";


export default function CourseIndexModuleTable(props) {

    function getTotalCourseTime(courseModules, wordsPerMinute) {
        // If the total time of modules was 4 minutes 45, below would calculate 4.75
        let courseTimeMinutes = 0;
        for (let i = 0; i < courseModules.length; i++) {
            let module = courseModules[i];
            courseTimeMinutes = 60;
            // if (module.length !== null){
            //     let time = module.duration.split(":");
            //     if (time.length > 2) {
            //         courseTimeMinutes += time[0];
            //         time.shift();
            //     }
            //     if (time.length > 1) {
            //         courseTimeMinutes += time[0];
            //         time.shift();
            //     }
            //     courseTimeMinutes += Math.round(time[0] / 6) / 10;
            // }
            // else if (module.wordCount !== null) {
            //     // let minutes = module.wordCount / wordsPerMinute;
            //     // courseTimeMinutes += Math.floor(minutes);
            //     // courseTimeMinutes += Math.round((minutes - Math.floor(minutes)) * 6) / 10;
            //     courseTimeMinutes += module.wordCount / wordsPerMinute;
            // }
        }

        let hours = Math.floor(courseTimeMinutes / 60);
        if (hours === 0) {
            let mins = Math.round(courseTimeMinutes - hours);
            return `${mins} minutes`
        }
        return `${hours} hours`
    }

    const totalTimeDesc = getTotalCourseTime(props.courseModules, 200);

    function convertModuleTime(module, wordsPerMinute) {
        if (module.length !== null) {
            return module.length
        }
        else if (module.wordCount !== null) {
            let minutes = module.wordCount / wordsPerMinute;
            let hours = Math.floor(minutes / 60);
            let seconds = Math.round((minutes - Math.floor(minutes)) * 60);
            let timeString = "";
            if (hours !== 0) {
                timeString += `${hours}:`;
            }
            timeString += `${minutes - (hours*60)}:`;
            if (seconds < 10) {
                timeString += `0${seconds}`;
            }
            else {
                timeString += `${seconds}`;
            }
            return timeString;
        }
    }

    function renderModuleTableRows(courseModules) {
        let courseRows = [];

        for (let i = 0; i < courseModules.length; i++) {
            const module = courseModules[i];
            var typeIcon;
            if (module.type === "video") {
                typeIcon = <i className="fa-regular fa-circle-play group-hover:text-blue"></i>;
            }
            else if (module.type === "article") {
                typeIcon = <i className="fa-solid fa-book hover:text-blue group-hover:text-blue"></i>;
            }
            else if (module.type === "code") {
                typeIcon = <i className="fa-solid fa-code hover:text-blue group-hover:text-blue"></i>;
            }

            var moduleTime;
            if (module.resource != null) {
                moduleTime = <p className="font-light group-hover:text-blue">{convertModuleTime(module, 200)}</p>;
            }
            else {
                moduleTime = <p className="font-light text-sm group-hover:text-blue">Coming Soon</p>;
            }

            courseRows.push(
                <Link to={module.resource != null ? module.resource : '/coming-soon/'}>
                    <div className="flex justify-between border-primary-50 border-t-[1px] py-2 mobile:px-2 px-6 text-xl items-center group">
                        <div className="flex space-x-4 items-center">
                            {typeIcon}
                            <p className="group-hover:text-blue">{i}. {module.name}</p>
                        </div>
                        {moduleTime}
                    </div>
                </Link>);
        }
        return courseRows;
    }

    const tableRows = renderModuleTableRows(props.courseModules);

    return (
        <div className="border-primary-50 border-[1px]">
            <div className="flex items-center justify-between mobile:px-2 px-4 py-4">
                <h4 className="text-2xl font-semibold">Build a {props.courseTitle}</h4>
                <div className="flex flex-col font-light">
                    <p>{props.courseModules.length} Lessons</p>
                    <p>{totalTimeDesc}</p>
                </div>
            </div>
            {tableRows}
        </div>
    )
}